// Default font family
//

$default-font-family: 'Clan', Helvetica, sans-serif !important;

// Colors
//

$color-primary: #000 default;
$color-primary-light: #FFF !default;
$color-primary-dark: #000 !default;
$color-primary-dark: #000 !default;

.cs-message-list {
    background-color: #333 !important;
}

.cs-chat-container {
    background-color: #333 !important;
}

.cs-message-list__item {
    background-color: #333 !important;
}

.cs-message-list__item--outgoing {
    background-color: #333 !important;
}

.cs-message-input {
    background-color: #222 !important;
}

.cs-message__content {
    background-color: #555 !important;
    color: #ddd !important;
}

.cs-message__content--outgoing {
    background-color: #182c46 !important;
    color: #ddd !important;
}

.cs-message-input__content-editor-wrapper {
  background-color: #444 !important;
}
.cs-message-input__content-editor {
  background-color: #444 !important;
  color: #ddd !important;
}