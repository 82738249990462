/* Icon Button */
.dm-icon-button {
    --button-size: calc(var(--nav-size) * 0.5);
    width: var(--button-size);
    height: var(--button-size);
    background-color: #484a4d;
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
}

.dm-icon-button:hover {
    filter: brightness(1.2);
}

.dm-icon-button svg {
    fill: var(--text-color);
    width: 20px;
    height: 20px;
}
.dm-parent-div {
    position: relative;
}
/* Dropdown Menu */

.dm-dropdown {
    position: fixed;
    width: 300px;
    background-color: var(--bg);
    border: var(--border);
    border-radius: var(--border-radius);
    padding: 1rem;
    overflow: hidden;
    transition: height var(--speed) ease;
}

.dm-menu {
    width: 100%;
    background-color: #38393a;
}

.dm-menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    padding: 0.5rem;
    text-decoration: none;
    cursor: pointer;
}

.dm-menu-item .icon-button {
    margin-right: 0.5rem;
}


.dm-menu-item .icon-button:hover {
    filter: none;
    text-decoration: none;
}

.dm-menu-item:hover {
    background-color: #525357;
    color: #c4c4c4;
    text-decoration: none;
}

.dm-icon-right {
    margin-left: auto;
}

/* CSSTransition classes  */
.dm-menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
}
.dm-menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
}
.dm-menu-primary-exit {
    position: absolute;
}
.dm-menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
}

.dm-menu-secondary-enter {
    transform: translateX(110%);
}
.dm-menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
}
.dm-menu-secondary-exit {

}
.dm-menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
}