.react-flow__node-comfyui {
    font-size: 12px;
    background: #333;
    border: 4px solid #222;
    border-radius: 2px;
}

.react-flow__node-group {
    font-size: 12px;
    background: #333;
    border: 4px solid #222;
    border-radius: 2px;
}

.comfy-input {
    padding-left: 8px;
    padding-right: 16px;
}

.comfy-output {
    padding-left: 16px;
    padding-right: 8px;
}
.comfy-data {
    padding-left: 16px;
    padding-right: 16px;
    max-width: 400px;
}

.comfy-table {
    background-color: #232323;
}

.comfy-overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 32px;
    padding-left: 96px;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 1000; /* Specify a stack order in case you're using a different order for other elements */
}