$theme-colors: (
  "primary": #c6b070,
  "secondary": #46462d,
  "info": #11cdef,
  "success": #2dce89,
  "danger": #f5365c,
  "warning": #fb6340,
  "light": #212020,
  "dark": #b0b0b0
) !default;

.mdicon {
  font-size: 24px;
  vertical-align: middle;
}

.md-edit-icon {
    font-size: 40px;
    vertical-align: middle;
    color: #c6b070;
}

.vertical-align {
  vertical-align: middle;
  display: flex;
  align-items: center;
}

/* image grid styles */
.img-grid{
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
}
.img-wrap{
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
.img-wrap img{
  min-width: 100%;
  min-height: 100%;
  max-width: 150%;
  position: absolute;
  top: 0;
  left: 0;
}

/* modal styles */
.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 100;
}
.backdrop img{
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 3px solid white;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #e1e1e1;
  text-align: left;
  list-style: none;
  background-color: #313131;
  background-clip: padding-box;
  border: 1px solid rgba(114, 114, 114, 0.15);
  border-radius: 0.25rem;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #e0e0e0;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #626262;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #bebebe;
  text-decoration: none;
  background-color: #3d3b37;
}

.dropdown-item.active, .dropdown-item:active {
  color: #e1e1e1;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dark-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
}

.dark-scrollbar::-webkit-scrollbar-thumb {
  background-color: #212932;
  border-radius: 10px;
}

.scrollable-menu {
  max-height: 200px;
  overflow-y: auto;
}
.scrollable-menu::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
}

.scrollable-menu::-webkit-scrollbar-thumb {
  background-color: #212932;
  border-radius: 10px;
}

.app-body {
  overflowY: "auto";
}
.custom-scroll::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #212932;
  border-radius: 10px;
}
div::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
}

div::-webkit-scrollbar-thumb {
  background-color: #212932;
  border-radius: 10px;
}
::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #212932;
  border-radius: 10px;
}

.nav-tabs .nav-link {
  background-color: transparent !important;
  color: #c6b070 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.nav-tabs .nav-link.active {
  border-bottom: solid 4px white !important;
}
