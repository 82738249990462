/* JSON editor dark theme */
.jsoneditor {
    background-color: #1a1a1a;
    color: #e0e0e0;
}

.jsoneditor-search input[type="text"] {
    background-color: #222;
    color: #eee;
    border: 1px solid #333;
}

.jsoneditor-search input[type="text"]:focus {
    background-color: #333;
    color: #fff;
}

.jsoneditor .jsoneditor-table,
.jsoneditor .jsoneditor-tree {
    background-color: #222;
    color: #eee;
}

.jsoneditor .jsoneditor-tree a,
.jsoneditor .jsoneditor-tree .jsoneditor-field,
.jsoneditor .jsoneditor-tree .jsoneditor-value {
    color: #ccc;
}

.jsoneditor .jsoneditor-tree .jsoneditor-expandable .jsoneditor-clickarea:before {
    color: #777;
}

.jsoneditor .jsoneditor-tree .jsoneditor-expandable.jsoneditor-expanded .jsoneditor-clickarea:before {
    color: #ccc;
}

.jsoneditor .jsoneditor-field {
    background-color: #333;
    color: #eee;
    border-color: #444;
}

.jsoneditor .jsoneditor-field.jsoneditor-active {
    background-color: #555;
    border-color: #777;
}

.jsoneditor .jsoneditor-value {
    background-color: #333;
    color: #ccc;
    border-color: #444;
}

.jsoneditor .jsoneditor-value.jsoneditor-active {
    background-color: #555;
    border-color: #777;
}

.jsoneditor .jsoneditor-menu {
    background-color: #222;
    color: #eee;
    border: 1px solid #333;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.jsoneditor .jsoneditor-menu-item:hover {
    background-color: #444;
    color: #eee;
}

.jsoneditor .jsoneditor-menu-item.jsoneditor-active {
    background-color: #555;
    color: #eee;
}

.jsoneditor-frame {
    background-color: #444;
    color: #eee;
}

.jsoneditor-outer {
    background-color: #111;
    color: #eee;
}

.jsoneditor-field-highlight {
    background-color: #555;
}

.jsoneditor-highlight {
    background-color: #293e54;
    border: 1px solid #0f213a;
}

.jsoneditor-highlight-active:hover {
    background-color: #304b65;
    border: 1px solid #0f213a;
    border-radius: 2px;
}

