.code-box {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    padding: 8px;
    background-color: #2b2c2d;
}

.code-title {
    background-color: #2c2c2c;
    color: #9b9b9b;
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
}

.copy-button {
    position: absolute;
    top: 13px;
    right: 10px;
    background-color: #2c2c2c;
    color: white;
    border: none;
    padding: 0.25rem 0.75rem;
    font-size: 0.85rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.copy-button:hover {
    background-color: #444;
}

.code-content {
    transition: max-height 0.5s ease, opacity 0.3s ease;
    overflow: auto;
    opacity: 1;
}

.code-content.collapsed {
    max-height: 0;
    opacity: 0;
    padding: 0;
}