.dark-select {
    background-color: #343a40; /* Adjust the color to match your dark theme */
    color: white;
    border-color: #343a40;
    min-height: 32px;
}

.dark-select:focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.25);
    border-color: #50585e;
}