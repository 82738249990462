.lightbox-portal {
  z-index: 2000;
}

.progress-bar {
    background-color: #b29175 !important;
}

div[role="menu"] {
  background-color: rgb(32, 32, 32) !important;
  color: #ccc;
}
div[role="menuitem"]:hover {
  background-color: rgb(27, 27, 27) !important;
  color: #ffffff !important;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #555;
  background-color: #333333 !important;
}
div.jsoneditor-field[contenteditable=true]:focus, div.jsoneditor-field[contenteditable=true]:hover, div.jsoneditor-value[contenteditable=true]:focus, div.jsoneditor-value[contenteditable=true]:hover, div.jsoneditor-field.jsoneditor-highlight, div.jsoneditor-value.jsoneditor-highlight {
  background-color: #333333 !important;
  border: 1px solid #b9b597 !important;
}
.sidenav-subnav > .sidenav-subnavitem {

  > .navitem {
    &:hover {
      background: #000 !important;
    }
  }
}

.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
}

.flex-items:nth-child(1) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.flex-items:nth-child(2) {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.message-preview {
  font-size: smaller;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.message-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.message-edit-icon {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.5rem;
  cursor: pointer;
}

.message-header-icons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.message-abort-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}

.message-header-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.message-content-editable {
  width: 100%;
  height: 100%;
  resize: none;
  border: none;
  padding: 0;
  overflow-y: auto;
  color: white;
}
.message-preview-container {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  overflow: hidden;
}

.message-tools-container {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.message-expanded .message-preview-container {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.message-expanded .message-tools-container {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.message-preview-wrapper {
  position: relative;
  width: 100%;
  height: 20px;
}

.message-preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.wrapped-pre {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
  color: #cccccc;
}

/* Override the background and text color of the modal */
.modal-content {
  background-color: #333333;
  color: #ffffff;
}

/* Override the background color of the modal header */
.modal-header {
  background-color: #212529;
}

/* Override the border color of the modal footer buttons */
.modal-footer button {
  border-color: #ffffff;
}

.obfuscated-key-content {
  font-family: 'Roboto Mono', monospace;
}

.create-card-flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: normal;
  align-content: normal;
}

.create-card-flex-items:nth-child(1) {
  display: block;
  flex-grow: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.create-card-flex-items:nth-child(2) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 1;
}

.collapsable-card-subtitle {
    font-size: smaller;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #757575;
}

.create-card-flex-between-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: normal;
  align-content: normal;
}
.create-card-flex-between-items {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}
.create-card-flex-between-items-grow {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.create-card-content {
  flex: 1 1 auto;
  display: flex;
  overflow: auto;
  flex-direction: column;
}

.create-card-icon {
  font-size: 32px;
  margin: 4px;
}


.flex-column-container {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: normal;
  align-content: normal;
}

.flex-column-items {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.blockquote {
  border-left: 4px solid #ccc;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  color: #ccc;
}

code {
  color: #f8f8f2; /* Set the text color to white */
  background-color: #282a36; /* Set the background color to a dark shade */
  padding: 0.2em 0.4em; /* Add some padding */
  font-size: 0.9em; /* Reduce the font-size */
}

.card {
  color: #cccccc
}

pre {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
  color: #cccccc;
}

.item-list{
  padding: 0;
  margin: 0;
  list-style: none;
  li{
    position: relative;
    a{
      font-weight: 500;
      font-size: 14px;
      letter-spacing: .6px;
      display: block;
      padding: 14px 20px;
      border-bottom: 1px solid rgba(255,255,255,.1);
      background: #35393d;
      transition: all 0.3s ease;
      cursor: pointer;
      color: #cccccc;
      text-decoration: none;
      &:hover{
        background: #555;
        transition: all 0.3s ease;
      }
      &.active{
        background: #555;
      }
    };
    div.item{
      font-weight: 500;
      font-size: 14px;
      letter-spacing: .6px;
      display: block;
      padding: 14px 20px;
      border-bottom: 1px solid rgba(255,255,255,.1);
      background: #353b3f;
      transition: all 0.3s ease;
      cursor: pointer;
      color: #cccccc;
      text-decoration: none;
      &:hover{
        background: #28303d;
        transition: all 0.3s ease;
      }
      &.active{
        background: #28303d;
      }
    }
  }
}

.mdClickableIcon {
  font-size: 40px;
  color: #ccc;
  transition: color 0.3s; /* smooth transition for color change */
}

.mdClickableIcon:hover {
  color: #b29175;
}