.pageheader-root {
    padding: 0px;
    margin: -16px;
    position: relative;
    overflow: auto;
}

.pageheader-header {
    background-color: #33333333;
    color: white;
    height: 96px;
    min-width: 711px;
    position: sticky;
    z-index: 1;
    top: 0;
    background-image: url('https://cdn.midjourney.com/d2649236-83a5-4414-9a08-094856a0e3bd/0_0.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    overflow-y: hidden;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
}

.pageheader-header-image {
    height: 200px;
    position: absolute;
    top: 0px;
}


.pageheader-spacer {
    height:300px;
}

div.pageheader-image-container {
    width: 100%;
    height: 400px;
    min-width: 711px;
    background-image: url('https://cdn.midjourney.com/d2649236-83a5-4414-9a08-094856a0e3bd/0_0.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    position: absolute;
}

.pageheader-content-body {
    margin-top: 32px;
    margin-left: 32px;
    margin-right: 32px;
}

.pageheader-header-container {
    background: rgba(0, 0, 0, .4)
}

.pageheader-header-content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: normal;
    align-content: normal;
}

.pageheader-header-title-row {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.pageheader-left {
    align-self: flex-start;
    height: 96px;
    padding: 8px 32px
}

.pageheader-right {
    align-self: flex-end;
    height: 96px;
    padding: 38px 32px
}

.pageheader-title {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.25;
    margin-top: 8px;
    padding: 0;
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pageheader-breadcrumb {
    margin-top: 4px;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
}

.pageheader-breadcrumb a {
    color: #fff;
    text-decoration: none;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
}

.pageheader-breadcrumb a:hover {
    color: #aba688;
}