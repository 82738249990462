.agent-grid {
    padding: 20px;
}

.search-field {
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    font-size: 16px;
}

.grid {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.namespace-group {
    margin-bottom: 40px;
}

.namespace-title {
    margin-top: 32px;
    margin-bottom: 15px;
    font-size: 20px;
    color: #ffffff;
}

.namespace-agents {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 4px;
}

.agent-box {
    background-color: #343a40;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease;
    padding: 10px;
    width: 100%;
}

.agent-box:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.agent-box-content {
    padding: 10px;
}

.agent-name {
    margin: 0 0 10px;
    font-size: 18px;
    color: #ffffff;
}

.agent-description {
    margin: 0;
    font-size: 14px;
    color: #b0b0b0;
}