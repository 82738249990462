.category-section {
    margin-bottom: 32px; /* Extra space between root categories */
}

.subcategory-title {
    font-size: smaller; /* Make subcategories smaller text */
    margin-left: 10px; /* Indent subcategories slightly */
}

.namespace-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.namespace-agents {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.agent-box {
    border: 1px solid #444; /* Darker border to match dark theme */
    padding: 4px; /* Reduced padding for more content space */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 200px; /* Consistent tile width */
    height: 130px; /* Adjusted height to fit two lines of description */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    background-color: #222; /* Dark theme tile background */
    color: #ddd; /* Light text for dark theme */
}

.agent-box:hover {
    background-color: #0a0a0a; /* Slightly darker on hover */
}

.agent-box-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.agent-name {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.agent-description {
    font-size: 10px;
    color: #aaa; /* Slightly lighter text for contrast */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
