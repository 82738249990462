/* src/agent-gridGrid.css */
.agent-grid-grid {
    padding: 20px;
}

.search-field {
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    font-size: 16px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

.agent-grid-box {
    background-color: #282d2f;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.agent-grid-box :hover {
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.agent-grid-box-content {
    padding: 20px;
}

.agent-grid-title {
    margin: 0 0 10px;
    font-size: 18px;
}

.agent-grid-description {
    margin: 0;
}
