
.set-gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;
    cursor: pointer;
}

/* For medium-sized screens (e.g., tablets), reduce to 2 columns */
@media (max-width: 1024px) {
    .set-gallery {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-width: 50%;
        -moz-column-width: 50%;
        column-width: 50%;
        cursor: pointer;
    }
}

/* For small screens (e.g., mobile phones), reduce to 1 column */
@media (max-width: 760px) {
    .set-gallery {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
        cursor: pointer;
    }
}

.set-gallery .set-card {
    transition: all 350ms ease-in-out;
    cursor: pointer;
    margin-bottom: 12px;
}