/* src/PresetGrid.css */
.preset-grid {
    padding: 20px;
}

.search-field {
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    font-size: 16px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 4px;
}

/* CSS Styles (presetgrid.css) */
.preset-box {
    background-color: #282d2f;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: box-shadow 0.3s ease;
}

.preset-box:hover {
    background-color: #3b4244;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.preset-box-content {
    padding: 20px;
}

.preset-title {
    margin: 0 0 10px;
    font-size: 18px;
}

.preset-description {
    margin: 0;
}

/* CSS Styles (presetgrid.css) */
.category-section {
    margin-bottom: 32px; /* Extra space between root categories */
}

.subcategory-title {
    font-size: smaller; /* Make subcategories smaller text */
    margin-left: 10px; /* Indent subcategories slightly */
}

.grid-box {
    border: 1px solid #444; /* Darker border to match dark theme */
    padding: 4px; /* Reduced padding for more content space */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 200px; /* Consistent tile width */
    height: 96px; /* Adjusted height to fit two lines of description */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    background-color: #222; /* Dark theme tile background */
    color: #ddd; /* Light text for dark theme */
}

.grid-box:hover {
    background-color: #0a0a0a; /* Slightly darker on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.grid-box-content {
    padding: 20px;
}

.grid-box-title {
    margin: 0 0 10px;
    font-size: 18px;
}

.grid-box-description {
    margin: 0;
}
