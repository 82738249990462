.lightbox-container .ReactModal__Overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(255, 255, 255, 0.75);
}

.lightbox-container .ReactModal__Content {
    position: absolute;
    top: 50px; /* Adjust to position within the container */
    left: 50px; /* Adjust to position within the container */
    right: 50px; /* Adjust to position within the container */
    bottom: 50px; /* Adjust to position within the container */
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
}
.slide-pane {
    background-color: #212529; /* or use a specific hex color like #A9A9A9 */
}

.slide-pane__header-title {
    color: #1f1f1f; /* Change color as needed */
    font-size: 20px; /* Adjust the font size as needed */
    /* Add other CSS properties as needed */
}
body > div.ReactModalPortal > div > div > div.slide-pane__header
{
    background-color: #1f1f1f; /* or use a specific hex color like #A9A9A9 */
}
.slide-pane__header-title-wrapper {
    color: #1f1f1f; /* Change color as needed */
    font-size: 20px; /* Adjust the font size as needed */
    /* Add other CSS properties as needed */
}
.centered-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* This takes the full height of the viewport */
}