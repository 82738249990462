.ellipsis {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.table-container {
   display: flex;
   flex-direction: column;
   height: 100%;
}

.table-content {
   flex-grow: 1;
   overflow: auto;
}

.cell-content {
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
}

a {
   color: #abab8a;
}